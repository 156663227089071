.ItemVideo-itemVideoContainer {
    max-width: 730px;

    :global {
        // otherwise svg-icons very small
        .rh5v-Volume_icon, .rh5v-Captions_icon, .rh5v-PlayPause_icon, .rh5v-Fullscreen_icon{
            padding:0;
        }

        .rh5v-DefaultPlayer_component {
            height: 100%;
            width: 100%;
        }
    }
}
