.FavoritesButton-buttonContainer {
    display: inline;
    background-color: inherit;
}

.FavoritesButton-favoriteButton {
    z-index: 1;
    background-color: inherit;
    background-repeat: no-repeat;
    border: none;
    overflow: hidden;
    outline: none;
    color: inherit !important;
}

.FavoritesButton-favoriteButton:active, .FavoritesButton-favoriteButton:focus {
    box-shadow: none;
}

.FavoritesButton-listFavorite {
    float: right;
    //Factor small images size means smaller container. Search container has position: absolute;
    position: absolute;
    right: 5px;
    bottom: 0;
}

.FavoritesButton-gridFavorite {
    padding-bottom: 0;
    padding-top: 12px;
}

.FavoritesButton-itemFavorite {
    margin-top: 6px;
}

.FavoritesButton-saveIcon, .FavoritesButton-savedIcon {
    padding-right: 5px;
}

.FavoritesButton-buttonSpan {
    z-index: 1
}

.FavoritesButton-modalText {
    font-size: 1.1em;
}

.FavoritesButton-modalFooter {
    border-top: none;
}

.FavoritesButton-maxItemsModalHeader button{
    color: #767676;
    opacity: 1;
    &:hover {
        color: #767676;
        opacity: 1;
    }
}

.FavoritesButton-favoritesModal {
    top: 160px;
}

@media screen and (max-width: 812px) and (max-height: 414px){
    //Adjust for search header on horizontal mobile (including xl-sizes)
    .FavoritesButton-favoritesModal {
        top: auto;
    }
}
@media screen and (max-width: 768px) {
    .FavoritesButton-itemFavorite {
        padding-left: 15px !important;
    }

    .FavoritesButton-favoritesModal {
        top: 0;
    }
}
