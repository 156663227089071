@import '../../styles/variables';

.DateSearch-container {
    .form-group {
        margin-bottom: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h2 {
        margin: 0;
        margin-bottom: 7px;
    }
}

.DateSearch-footer {
    display: flex;

    div {
        flex: 1 1 100%;
        padding-left: 5px;
        padding-right: 5px;

        &:first-child {
            padding-left: 0px;
        }

        &:last-child {
            padding-left: 5px;
            padding-right: 0px;
        }

        span {
            white-space: nowrap;
        }
    }
}

.DateSearch-input {
    flex: 1 1 100%;
    margin-right: 10px;

    label {
        display: flex;
        align-items: center;

        span {
            margin-right: 10px;
        }

        input {
            flex: 1;
        }

        font-weight: normal;
        margin-bottom: 0;
    }
}

.DateSearch-flexContainer {
    display: flex;

    .input:last-child {
        margin-right: 0px;
    }
}

@media (min-width: $breakpoint-md) {
    .DateSearch-flexContainer, .DateSearch-footer {
        width: 50%;
    }
}
