@import 'styles/_variables';

.CompoundItemView-container {
    background-color: #FFFFFF;
    display: flex;
    margin-right: 0;
    margin-left: 0;
    padding: 5px 5px 4px 5px;

    &:hover {
        cursor: pointer;
    }
}

.CompoundItemView-selectedCompoundItem {
    background-color: #FFF08C!important;
}

.CompoundItemView-overlay {
    border-left: 1px solid #CCC;
    border-right: 1px solid #CCC;
    border-top: 1px solid #CCC;
    margin-right: -10px;
    margin-left: -10px;
    padding-bottom: 1px;
    &:hover {
        cursor: pointer;
    }
}

.CompoundItemView-searchResult {
    border-left: 5px solid #cd0a0a !important;
}

.CompoundItemView-header {
    padding-left: 10px;
}

.CompoundItemView-thumbnailHolder {
    max-width: 90px;
    max-height: 90px;
    display: inline-block;
}

.CompoundItemView-thumbnail {
    max-width: 90px;
    max-height: 90px;
}

.CompoundItemView-thumbnailHolder + .CompoundItemView-contentHolder {
    width: calc(100% - 160px);
    overflow: hidden;
    float: right;
}

/**
 * On desktop devices, show the side column, and hide the
 * duplicated components (option and search) in the main column.
 */
@media (min-width: $breakpoint-sm) {

    .CompoundItemView-container {
        width: inherit;
        background-color: #EFEFEF;
        &:hover {
            cursor: pointer;
        }
    }

    .CompoundItemView-overlay {
        background-color: #EFEFEF;
        margin-right: 0;
        margin-left: 0;
        &:hover {
            cursor: pointer;
        }
    }
}
