$buttonWidth: 38px;
.DesktopSearchHeader-container {

    select.form-control {
        margin-left: 5px;
        display: inline-block;
        /* messes with the text getting hidden */
        font-weight: 400;
        width: 75%;
    }
    label {
        font-size: .875rem;
        font-weight: normal;
    }
}

.ResultsDisplaySelector-displayOptionContainer {
    min-width: 2 * $buttonWidth;

    button {
        width: $buttonWidth;
        margin-top: 5px;
        margin-bottom: 3px;
        -webkit-appearance: none;
        padding:8px 11px 4px 9px;
    }

    span {
        font-size: 18px;
    }

    .ResultsDisplaySelector-searchHeaderBtnInactive {
        user-select: auto;
        background-color: #FFFFFF;
    }
}

.resultsPerPageSelector {
    margin-left: 5px;
}

.resultsPerPageSelectorContainer {
    margin-left: 15px;
}

.DesktopSearchHeader-searchHeaderRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background-color: #EFEFEF;
}

.DesktopSearchHeader-desktopSortBySelectLabel {
    margin: auto 0 auto 0;
}

.DesktopSearchHeader-desktopSortBySelectContainer {
    display:flex;
    justify-content: space-between;
    align-content: center;
}

