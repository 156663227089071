.ItemTitle-primaryTitle{
    overflow: hidden;
    position: relative;
    text-align: justify;
    padding-right: 1em;
    &:before {
        content: "...";
        position: absolute;
        right: 0;
        bottom: 0;
    }
    &:after {
        content: "";
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: .2em;
        background: #fff;
    }
}

.ItemTitle-clonedPrimaryTitle{
  position: absolute;
  top: 0;
  width: 10%;
  overflow: hidden;
  visibility: hidden;
  white-space: nowrap;
}

.ItemTitle-secondaryTitle{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

