@import 'styles/_variables';

.FieldSearch-container {
    :global .form-group {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h2 {
        margin: 0;
        margin-bottom: 7px;
    }
}

.FieldSearch-adjustGutter {
    :global .col-md-2 {
        padding-right: 5px;
    }

    :global .col-md-4 {
        padding-left: 5px;
    }
}

@media (min-width: $breakpoint-md) {
    .FieldSearch-adjustGutter2 {
        .col-md-8 {
            padding-right: 5px;
        }

        .col-md-4 {
            padding-left: 5px;
        }
    }
}

@media (max-width: $breakpoint-md) {
    .FieldSearch-container {
        .form-group .col-md-8 {
            margin-bottom: 10px;
        }
    }
}
