.MobileResultsOptionsHeader-container {
    composes: search-box from "styles/shared.css";
    background-color: #EFEFEF;
    border: 1px solid #EFEFEF;
    padding: 5px 10px 5px 10px;
    label {
        font-weight: normal;
    }
}

.MobileResultsOptionsHeader-resultsPerPageSelector {
    margin-left:5px;
}

.MobileResultsOptionsHeader-SupplementalHeaderBar {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
