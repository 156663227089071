
.ItemDownload-itemDownloadButtonPadding {
    padding: 0.25rem 0.5rem 0.25rem 0.5rem !important;
    border-radius: 5px;
}

.ItemDownload-itemDownload {
    display: inline-flex;
}

// override core.scss
.ItemDownload-itemDownloadDropdown{
    float: left!important;
    padding-right: 0!important;

    button{
        :global .caret{
            margin-top: -10px;
            border-width: 7px;
        }
    }
}
