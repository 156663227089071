@import '../../styles/variables';

.SavedItemsCreateLinkButton-modal-content {
    overflow: auto;
    height: auto;
}

.SavedItemsCreateLinkButton-modal-footer {
    padding-bottom: 7px;
    border-top: none;
}

.SavedItemsCreateLinkButton-createLinkSuccess {
    margin-top: 10px;
    padding: 5px 0 5px 5px;
    width: 100%;
    font-size: 14px;
}

.SavedItemsCreateLinkButton-copyHtmlSuccessMessage {
    margin-left: 5px;
}

.SavedItemsCreateLinkButton-link {
    padding-right: 0.5rem;
}

.SavedItemsCreateLinkButton-linkCreatedModal {
    top: 160px;
}

.SavedItemsCreateLinkButton-linkCreatedDialogText {
    font-size: 1.1em;
}

.SavedItemsCreateLinkButton-createLinkDialogFooter {
    border-top: none;
    left: -2px;
    top: -7px;
    position: inherit;
    display: block;
    clear: right;
    float: right;
}

.SavedItemsCreateLinkButton-linkCreatedHeader button{
    color: #767676;
    opacity: 1;
    &:hover {
        color: #767676;
        opacity: 1;
    }
}

.SavedItemsCreateLinkButton-desktopIcon {
    display: inherit;
    padding-right: 0;
    padding-left: 0.4rem;
    padding-bottom: 0.4rem;
}

.SavedItemsCreateLinkButton-mobileIcon {
    display: none;
}

@media screen and (max-width: 812px) and (max-height: 414px){
    //Adjust for search header on horizontal mobile (including xl-sizes)
    .SavedItemsCreateLinkButton-linkCreatedModal {
        top: auto;
    }
}

// Mobile View (Phone, Tablet)
@media screen and (max-width: $breakpoint-sm)  {
    .SavedItemsCreateLinkButton-link {
        margin-top: 3px;
    }

    // moz
    @supports (-moz-appearance:none) {
        .SavedItemsCreateLinkButton-link {
            padding: 0;
            margin: -1px 0 -1px 0; //90degree turn offsets icon
        }
    }

    .SavedItemsCreateLinkButton-buttonText {
        display: none;
    }

    .SavedItemsCreateLinkButton-desktopIcon {
        display: none;
    }

    .SavedItemsCreateLinkButton-mobileIcon {
        display: inline;
    }

    .SavedItemsCreateLinkButton-linkCreatedModal {
        top: 0;
    }
}
