.ItemFile-itemFile {
    display: flex;
}

.ItemFile-itemFileLink {
    padding: 5px 0 0 10px;
}

.ItemFile-itemImageThumbnail {
    max-width: 100%;
    flex: 0 0 auto;
    align-self: flex-start;
}

.ItemFile-thumbnailFlex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
