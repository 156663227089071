@import '../../styles/variables';

.SelectableItemListCard-container {
    padding: 0 0;
}

.SelectableItemListCard-linkContainer {
    composes: search-box from "styles/shared.css";
    margin: 15px 30px 0 50px;
    display: block;
    color: inherit;
    border: 1px solid #CCC;
    padding: 10px;

    &:hover {
        cursor: pointer;
    }
    &:visited, &:hover, &:focus {
        color: inherit;
    }
}

.SelectableItemListCard-selectItem {
    margin-left: 30px !important;
    position: absolute;
}

.SelectableItemListCard-thumbnailHolder {
    width: 150px;
    display: inline-block;
}

.SelectableItemListCard-thumbnail {
    max-width: 150px;
    max-height: 150px;
}

.SelectableItemListCard-thumbnailHolder + .SelectableItemListCard-contentHolder {
    width: calc(100% - 160px);
    overflow: hidden;
    float: right;
}

@media screen and (max-width: $breakpoint-sm)  {

    .SelectableItemListCard-container {
        padding-top: 20px;
    }

    .SelectableItemListCard-selectItem {
        margin: 10px 0 7px 15px !important;
        position: relative;
        padding-bottom: 5px;
    }

    .SelectableItemListCard-linkContainer {
        composes: search-box from "styles/shared.css";
        margin: 0 20px 0 15px;
        display: block;
        color: inherit;
        border: 1px solid #CCC;

        &:hover {
            cursor: pointer;
        }
        &:visited, &:hover, &:focus {
            color: inherit;
        }
    }
}
