.SearchCollectionFilter-container {

    padding-top: 5px;

    div label {
        line-height: 13px;
    }

    div input[type="checkbox"] {
        margin-top: 0;
    }
}

.SearchCollectionFilter-allCollectionsCheckbox {
    border-bottom: 1px dashed #ddd;
    padding-bottom: 5px;
    margin: 0;
}

.SearchCollectionFilter-btnWrapper {
    padding-right: 0;
    padding-left: 0;
}

.SearchCollectionFilter-btnContainer {
    padding-top: 10px;
    &:after{
        clear: both;
        display: table;
        content: " ";
    }

    .SearchCollectionFilter-btnWrapper:first-child {
        padding-right: 5px;
    }

    .SearchCollectionFilter-btnWrapper:last-child {
        padding-left: 5px;
    }
}
