@import 'styles/_variables';

.SharedItems-container {
    flex: 1 1 0;
    height: 100%;
    margin-bottom: 12px;
    margin-left: 5px;

    .SharedItems-displaySelectorAndResultsDesktopTop {
        display: inline;
        margin-left: 48%;
    }

    .SharedItems-displaySelectorAndResultsMobileTop {
        display: none;
    }

    .SharedItems-sharedItems {
        float: right;
        margin-right: 50px;
    }

    .SharedItems-alert {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }

    .SharedItems-spinner {
        margin: 3px 5px;
        height: 14px;
        width: 12px;
        animation: rotateSpinner 1.2s infinite linear;
        float: right;
    }

    @keyframes rotateSpinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .SharedItems-pageTitle {
        top: 16px;
        font-size: 1.125rem;
    }

    .SharedItems-faTitle {
        padding: .5em .5em 0 30px;
    }

    .SharedItems-sharedTitle {
        margin-left: 30px;
        font-size: .875rem;
    }

    .SharedItems-divider {
        margin-left: 30px;
        margin-right: 30px;
    }

    .SharedItems-noSavedItems {
        padding-top: 5px;
        margin-left: 30px;
    }

    .SharedItems-buttonContainer {
        margin-top: 10px;
    }

    .SharedItems-sharedItemContainer {
        padding-bottom: 20px;
    }
}

// Mobile View (Phone, Tablet)
@media screen and (max-width: $breakpoint-sm) {

    .SharedItems-container {

        .SharedItems-faTitle {
            padding: .5em .5em 0 15px;
        }

        .SharedItems-sharedTitle {
            margin-left: 15px;
        }

        .SharedItems-divider {
            margin-left: 15px;
            margin-right: 15px;
        }

        .SharedItems-alert {
            margin-left: 15px !important;
            margin-right: 15px !important;
        }

        .SharedItems-buttonContainer {
            .SharedItems-displaySelectorAndResultsDesktopTop {
                display: none;
            }

            .SharedItems-displaySelectorAndResultsMobileTop {
                display: block;
                margin-left: 30px;
            }

            .SharedItems-sharedItems {
                display: inline;
                float: right;
                margin-right: 34px;
                margin-top: -6px;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-md) and (min-width: $breakpoint-sm + 1) {
    .SharedItems-displaySelectorAndResultsDesktopTop, .SharedItems-displaySelectorAndResultsDesktopBottom {
        display: inline !important;
        margin-left: 46% !important;
    }
}
