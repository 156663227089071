.SearchTerm-searchTermClosePadding {
    padding-left: 0.5rem !important;
    padding-top: 0.3rem;
}

.SearchTerm-searchTermBtn {
    margin-right: 10px;
    margin-top: 5px;
}
.SearchTerm-term {
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
    font-size: 0.875rem !important;
}
