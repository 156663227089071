.SearchResult-container {
    composes: search-box from "styles/shared.css";
    margin-right: 0;
    margin-left: 0;
    display: block;
    color: inherit;
    margin-bottom: 10px;
    padding: 10px;

    &:hover {
        cursor: pointer;
    }
    &:visited, &:hover, &:focus {
        color: inherit;
    }
}

.SearchResult-thumbnailHolder {
    width: 150px;
    display: inline-block;
}

.SearchResult-thumbnail {
    max-width: 150px;
    max-height: 150px;
}

.SearchResult-thumbnailHolder + .SearchResult-contentHolder {
    width: calc(100% - 160px);
    overflow: hidden;
    float: right;
}

.SearchResult-favoritesListContainer {
    position: relative;
}
