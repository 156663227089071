.ExportSavedButton-modalText {
    font-size: 1.1em;
}

.ExportSavedButton-exportHeader button{
    color: #767676;
    opacity: 1;
    &:hover {
        color: #767676;
        opacity: 1;
    }
}

.ExportSavedButton-modalFooter {
    border-top: none;
}

.ExportSavedButton-exportModal {
    top: 160px;
}

.ExportSavedButton-download {
    padding-right: 0.5rem;
}

.ExportSavedButton-modalDownloadButton {
    display: inline-flex;
    max-height: 31px; //keep same size as cancel button
    margin-left: 0.5rem !important; //important needed for moz
}

.ExportSavedButton-formGroup {
    text-align: left;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.ExportSavedButton-selectControl {
    width: fit-content;
    width: -moz-fit-content;
    margin-left: 10px;
}

.ExportSavedButton-exportButton {
    display: inline;
}

.ExportSavedButton-exportModalBody {
    display: flex;
    align-items: center;
}

.ExportSavedButton-itemExportDropdown {
    float: left !important;
    padding-right: 0 !important;

    :last-child {
        margin-right: 10px;
    }
}

.ExportSavedButton-exportTag {
    margin: 0;
    padding-right: 10px;
}

.ExportSavedButton-desktopIcon {
    display: inline;
    margin-top: 3px;
}

.ExportSavedButton-mobileIcon {
    display: none;
}

@media screen and (max-width: 812px) and (max-height: 414px){
    //Adjust for landscape mobile
    .ExportSavedButton-exportModal {
        top: auto;
    }
}

@media screen and (max-width: 768px) {
    .ExportSavedButton-buttonText {
        display: none;
    }

    .ExportSavedButton-download {
        padding-right: 0;
    }

    .ExportSavedButton-desktopIcon {
        display: none;
    }

    .ExportSavedButton-mobileIcon {
        display: inline;
        margin-top: -1px;
    }

    .ExportSavedButton-exportModal {
        top: 0;
    }
}
