@import 'styles/_variables';


.CompoundItemViewer-paginator {
    margin: 0 -10px -15px;
}

.CompoundItemViewer-paginator > div > div > div {

    background-color: #fff;
    border-top: 1px solid #ccc;
    border-bottom: 5px solid #666;
}


.CompoundItemViewer-paginator > div {
    background-color: #fff;
}

.CompoundItemViewer-results {
    font-size: .875rem;
    font-weight: bold;
    display: block;
    padding: 0 5px 5px;
}

.CompoundItemViewer-container {
    font-size: 1px;
    border-top: 5px solid #666;
    margin: -10px -10px 5px;
}

/**
 * On desktop devices, show the side column, and hide the
 * duplicated components (option and search) in the main column.
 */
@media (min-width: $breakpoint-sm) {

    .CompoundItemViewer-paginator {
        margin: 0;
        background-color: #EFEFEF;

    }

    .CompoundItemViewer-paginator > div > div > div {

        background-color: #EFEFEF;
        border-top: 1px solid #ccc;
        border-bottom: 10px solid #CCC;
        border-left: 1px solid #CCC;
        border-right: 1px solid #CCC;
    }


    .CompoundItemViewer-paginator > div {
        background-color: #fff;
    }

    .CompoundItemViewer-nonprintable {
        border-top: 2px solid #CCC;
    }

    .CompoundItemViewer-mobile {
        display: none;
    }
}

@media print {
    // hiding through parent class not working in chrome
    // because of this, a new class has been added
    .CompoundItemViewer-nonprintable {
        display: none;
    }
}
