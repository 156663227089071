// For some reason this isn't getting imported correctly
// @value info-color, success-color, warning-color, danger-color, alert-text-color from 'styles/colors.css';

h2 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.dropdown.btn-group {
    float: right;
    padding-right: 10px;
}

.dropdown-menu {
    left: auto;
    right: 0;
    z-index: 1000;
}

#bg-nested-dropdown .caret {
    display: none;
}

.btn-default {
    color: #545454;
    background: #efefef;
    border-color: #bbb;
}

/*
 * Adding this into the project after I found out that the component that I
 * was working on now doesn't need btn-link anymore.
 */
.btn-link {
    background: white;
    color: #545454;
    border: 1px solid #bbb;
    border-radius: 4px;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        background: #CCCCCC;
    }

    &:focus {
        text-decoration: none;
    }
}

.btn-primary {
    border: 1px solid transparent !important;
}

.glyphicon {
    font-size: 18px;
}

input[type="search"]::-webkit-search-cancel-button {
    /* Bootstrap 3 override */
    -webkit-appearance: searchfield-cancel-button !important; // Creates cancel button in input field
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
    border-top: none;
}

.alert {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    color: #000000;
    // force the font-size to always be this size
    font-size: 0.875rem;

    .fa {
        padding-right: 5px;
    }

    &.alert-warning {
        background: #fff08c;
        border-color: #fff08c;
    }

    &.alert-danger {
        background: #ffbfbe;
        border-color: #ffbfbe;
    }

    &.alert-info {
        background: #d0e8f0;
        border-color: #d0e8f0;
    }

    &.alert-success {
        background: #d6edc9;
        border-color: #d6edc9;
    }
}

.pagination {
    font-size: 0.875rem;

    a[role="button"] {
        font-size: 0.875rem;
    }
}
