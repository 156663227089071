
@import 'base';
@import 'variables';
@import '~normalize.css/normalize';
@import 'vendor/bootstrap';
@import '~react-toggle/style';
@import '~react-html5video/dist/styles';

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
    box-sizing: border-box;
    font-size: 1.0em;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100%;
}

.headerIconButton {
    border: 0;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    background: #CCCCCC;
}

.grayDividerLine {
    height: 1px;
    background-color: #cccccc;
    margin: 10px auto 10px auto;
}

#root {
    height: 100%;
}

.titleDiv {
    padding-right: 10px;
}

.noscroll {
    overflow: hidden;
}

// IE11 hack
_:-ms-fullscreen, :root {
    .titleDiv {
        overflow: hidden;
        position: relative;
        -webkit-box-orient: horizontal !important;
        display: block !important;
    }
    .titleDiv:before {
        background: #FFFFFF;
        bottom: 0;
        position: absolute;
        right: 0;
        float: right;
        content: '\2026';
        margin-left: -3rem;
        width: 3rem;
    }
    .titleDiv:after {
        content: '';
        background: #FFFFFF;
        position: absolute;
        height: 50px;
        width: 100%;
        z-index: 1;
    }

    .truncation {
        overflow: hidden;
        position: relative;
        -webkit-box-orient: horizontal !important;
    }
    .truncation:before {
        background: #FFFFFF;
        bottom: 0;
        position: absolute;
        right: 0;
        float: right;
        content: '\2026';
        margin-left: -3rem;
        width: 3rem;
    }
    .truncation:after {
        content: '';
        background: #FFFFFF;
        position: absolute;
        height: 50px;
        width: 100%;
        z-index: 1;
    }
}

// edge hacks
@supports (-ms-ime-align:auto) {
    .titleDiv {
        overflow: hidden;
        position: relative;
        -webkit-box-orient: horizontal !important;
        display: block !important;
    }
    .titleDiv:before {
        background: #FFFFFF;
        bottom: 0;
        position: absolute;
        right: 0;
        float: right;
        content: '\2026';
        margin-left: -3rem;
        width: 3rem;
    }
    .titleDiv:after {
        content: '';
        background: #FFFFFF;
        position: absolute;
        height: 50px;
        width: 100%;
        z-index: 1;
    }

    .truncation {
        overflow: hidden;
        position: relative;
        -webkit-box-orient: horizontal !important;
    }
    .truncation:before {
        background: #FFFFFF;
        bottom: 0;
        position: absolute;
        right: 0;
        float: right;
        content: '\2026';
        margin-left: -3rem;
        width: 3rem;
    }
    .truncation:after {
        content: '';
        background: #FFFFFF;
        position: absolute;
        height: 50px;
        width: 100%;
        z-index: 1;
    }
}

// firefox hacks
@-moz-document url-prefix() {
    .titleDiv {
        overflow: hidden;
        position: relative;
    }
    .titleDiv:before {
        background: #FFFFFF;
        bottom: 0;
        position: absolute;
        right: 0;
        float: right;
        content: '\2026';
        margin-left: -3rem;
        width: 3rem;
    }
    .titleDiv:after {
        content: '';
        background: #FFFFFF;
        position: absolute;
        height: 50px;
        width: 100%;
        z-index: 1;
    }

    .truncation {
        overflow: hidden;
        position: relative;
    }
    .truncation:before {
        background: #FFFFFF;
        bottom: 0;
        position: absolute;
        right: 0;
        float: right;
        content: '\2026';
        margin-left: -3rem;
        width: 3rem;
    }
    .truncation:after {
        content: '';
        background: #FFFFFF;
        position: absolute;
        height: 50px;
        width: 100%;
        z-index: 1;
    }
}

.highlight {
    background-color: #FFF08C;
}

.highlightSelected {
    background-color: #FF9443;
}

/* Remove the 'X' from react-toggle */
.react-toggle-track-x {
    display: none;
}

/* Remove the checkmark from react-toggle */
.react-toggle-track-check {
    display: none;
}
.react-toggle:hover.react-toggle--disabled .react-toggle-track {
    background-color: #4D4D4D;
}

.react-toggle.react-toggle--checked:hover.react-toggle--disabled .react-toggle-track {
    background-color: #19AB27;
}

.react-toggle.react-toggle--disabled:active .react-toggle-thumb {
    box-shadow: none;
}

.react-toggle--disabled {
    cursor: default;
    opacity: 0.2;
}

.react-toggle--disabled.react-toggle--checked {
    opacity: 0.5;
}

/**
 * Video player overrides.
 */
 video, .video {
     max-width: 100%;
     background: black;
     // to avoid a black bar on the right side of the video
     // display: block;
 }
 .video-icon {
     &, &::before {
         display: block;
         font-family: FontAwesome;
         font-size: 14px;
         line-height: 1;
         width: 1em;
         text-align: center;
         margin: 0;
     }

     &--play-1::before {
         content: '\f04b';
     }
     &--pause-1::before {
         content: '\f04c';
     }
     &--volume-off::before {
         content: '\f026';
     }
     &--volume-down::before {
         content: '\f027';
     }
     &--volume-up::before {
         content: '\f028';
     }
     &--resize-full::before {
         content: '\f065';
     }
     &--resize-small::before {
         content: '\f066';
     }
 }
 .video-overlay .video-icon::before {
     font-size: 28px;
     margin: 0 auto;
 }

 // hide download button for chrome
 // http://stackoverflow.com/questions/39602852/disable-download-button-for-google-chrome
 video, audio {
     &::-webkit-media-controls{
         overflow: hidden;
     }

     &::-webkit-media-controls-enclosure{
         width: calc(100% + 32px);
         margin-left: auto;
     }
 }

 button[disabled].btn,
 button[disabled].btn:hover,
 .pagination>.disabled>span,
 .pagination>.disabled>span:hover {
    background-color: #EDEDED !important;
    color: #888 !important;
    border: 1px solid #bbb !important;
 }

 #global-noscript-warning{
     position: relative;

     .alert{
         border-radius:0
     }

     .alert-title,.alert-message{
         padding-left: 20px;
     }

     .fa{
        position: absolute;
        left: 12px;
        font-size: 1.33333em;
     }
 }

 cdm-map {
    width:100%;
    height: 200px;
    display: block;
    .leaflet-pane {
        width: 100%;
    }

    .leaflet-popup{
        padding: 0;
    }

    .leaflet-popup-content-wrapper{
        cursor: pointer;
    }

    .media {
        width: 100%;
        display: flex;

        .media-left {
            display: block;
            max-width: 33%;

            .media-object{
                width: 100%;
                max-width: 150px;
                max-height: 150px;
            }
        }

        .media-body{
            display: block;
            width: auto;
            flex: 1;
        }
    }
 }
