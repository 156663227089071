@value text-color from 'styles/colors.css';

.SimpleSearch-headerButtonPadding, .SimpleSearch-stackedHeaderButtonPadding {
    padding: 0.25rem 0.5rem 0.25rem 0.5rem !important;
}

.SimpleSearch-searchHolder {
    margin-left: 10px;
}

.SimpleSearch-advancedSearch {
    display: none;
}

.SimpleSearch-headerAdvancedSearchButtonLink:hover {
    text-decoration: underline !important;
}

.SimpleSearch-searchInput {
    margin-top: 4px;
    width: 300px;
}

.SimpleSearch-searchInputControl {
    width: 100%;
    font-size: 1rem;
    color: text-color;
}

.SimpleSearch-searchInputLabel {
    float: right;
    background-color: transparent !important;
    padding: 0 !important;
}

.SimpleSearch-searchInputLabel a {
    font-weight: bold !important;
    text-decoration: none !important;
}

.SimpleSearch-searchBox{
    float: right;
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 767px)  {

    .SimpleSearch-searchInput {
        flex: 1;
        width: auto;
    }

    .SimpleSearch-searchInputControl {
        font-size: 17px !important;
    }

    .SimpleSearch-searchInputLabel {
        display: none;
    }

    .SimpleSearch-advancedSearch {
        display: inline-block;
    }

    .SimpleSearch-searchBox{
        position: absolute;
        top: 60px;
        width: calc(100% - 30px);
        padding: 0 15px;
    }
}
