.Goto-form{
    margin-left: 2em;
    margin-bottom: 8px;

    h3{
        margin: 0 5px 0 0;
        display: inline-block;
        vertical-align: middle;
        padding: 10px 0;
    }

    button {
        margin-left: 0.5em;
        span {
            vertical-align: middle;
        }
    }

    button:hover{
        border-color: #bbb;
    }

    label{
        font-weight: inherit;
        margin: 0;
    }

    input {
        border: 1px solid #888;
        font-size: 1rem;
        padding: 3px 0 3px 3px;
        line-height: 0;
        vertical-align: middle;
        color: #545454;
    }
}
