@import "styles/_variables.scss";

.CoreLayout-headerContainer {
  flex: 0 0 auto;
  @media (min-width: $breakpoint-sm) {
    position: fixed;
    width: 100%;
    z-index: 49999;
  }
}

.CoreLayout-mainWrapperContainer {

  display: flex;
  flex-direction: column;

  flex: 8;
  position: relative;
  bottom: 0;
  @media (min-width: $breakpoint-sm) {
    margin-top: 155px;
  }

  @media print {
    // it (inherit|initial) fixes print preview on chrome
    overflow-y: inherit;
  }
}

.preview-mode {
  .CoreLayout-mainWrapperContainer {
    @media (min-width: $breakpoint-sm) {
        margin-top: 241px;
    }
  }
}

.CoreLayout-maincontainer {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  height: 100%;
}

.CoreLayout-mainwrapper {
    margin-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

:global(.noscroll){
    :local(.CoreLayout-mainWrapperContainer){
        position: static;
    }
}
