.CardWrapper-cardscontainer {
    margin-right: -5px;
    margin-left: -5px;
}

.Card-fullcard {
    composes: box from "styles/shared.css";
    height: 170px;
    display: block;
    color: inherit;

    &:visited, &:hover{
        color: inherit;
    }
}

@media (min-width: 500px) {
    .Card-fullcard:after {
        content: "";
        display: table;
        clear: both;
    }
}

.Card-cardImageHolder{
    width: 120px;
    display: inline-block;
    padding: 0;
    margin: 10px;
}

.Card-cardImage {
    max-width: 150px;
    max-height: 150px;
}

.Card-cardTextHolder {
    padding: 5px;
    width: calc(100% - 170px);
    height: 170px;
    overflow: hidden;
    float: right;
}

.Card-cardTextHolder a {
    color: #0B76B8;
}

// A lot of this code is coming from http://revelry.co/multi-line-ellipsis-using-pure-css/
.Card-cardText {
    overflow: hidden;
    position: relative;
}

.Card-cardcontainer {
    padding-bottom: 10px;
    padding-right: 5px;
    padding-left: 5px;
}
