.Lightbox-lightbox {
    position: fixed;
    top: 2.5%;
    bottom: 2.5%;
    left: 2.5%;
    right: 2.5%;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 10px;
    z-index: 50000;

    .btn-group{
        position: absolute;
        padding: 5px 10px;
        top: 0;
        right: 0;
        font-size: 21px;
    }

    button {
        color: #ccc;
        background: transparent;
        border: none;
        padding: 0px 10px;

        .text {
            display: none;
        }
    }

    button:hover {
        color: #FFF
    }
}
