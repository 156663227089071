@import 'styles/_variables';

.Breadcrumbs-breadcrumb span:first-child {
    padding: 0 0.5rem 0 0.5rem;
}

.Breadcrumbs-breadcrumb {
    overflow: hidden;
    padding-left: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Breadcrumbs-breadcrumb {
    flex-shrink: 0;
}

.Breadcrumbs-breadcrumb:last-child {
    flex-shrink: 1;
}

/**
 * The breadcrumbs are hidden on mobile devices.
 */
.Breadcrumbs-breadcrumbs {
    display: none;
}

/**
 * On desktop devices, show the breadcrumbs.
 */
@media (min-width: $breakpoint-sm) {
    .Breadcrumbs-breadcrumbs {
        display: flex;
    }
}

@media print {
    .Breadcrumbs-breadcrumbs {
        display: none;
    }
}
