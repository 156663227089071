.GridSearchResult-container {
    display: inline-flex;
    color: inherit;
    width: 180px;
    margin:5px 10px 5px 0;
    padding: 10px;
    border-radius: 5%;
    background-color: white;
    text-align: center;
    vertical-align: top;
    height: 200px;
    flex-direction: column;
    text-decoration: none;
    &:hover, &:visited {
        text-decoration: none;
        color: inherit;
    }
}

.GridSearchResult-favoritesContainer {
    height: inherit;
}

.GridSearchResult-thumbnail {
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: 160px;
    max-height: 120px;
}

.GridSearchResult-cardTitle {
    display: block;
    height: 60px;
    text-overflow: ellipsis;
    overflow: hidden;
    width:160px;
}

.GridSearchResult-thumbnailHolder {
    display: block;
    width: 160px;
    height: 120px;
}

.GridSearchResults-view {
    font-size: 1rem;
    font-weight: bold;
}

// https://jira.oclc.org/browse/CDMRUI-3400
@media (max-width: 400px) {

    .GridSearchResult-thumbnailHolder {
        width: 140px;
    }

    .GridSearchResult-thumbnail {
        max-width: 140px;
    }

    .GridSearchResult-container {
        width: 160px;
    }

    .GridSearchResult-cardTitle {
        width:140px;
    }
}
