
.CollectionNotFound-maincontainer {
    composes: box from "styles/shared.css";
    margin-bottom: 10px;

    display: flex;
    flex: 1 0 auto;
    flex-direction: column;

}

.CollectionNotFound-maincontentCollectionNotFound {
    composes: contentContainer from "styles/shared.css";
}
