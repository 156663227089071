@import "styles/_variables";

.ItemMetadata-metadatarow {

    td {
        padding: 5px 5px 5px 0 !important;
        word-wrap: break-word;
        word-break: break-all;
        &.ItemMetadata-key {
            font-weight: bold;
            width: 1px;
            white-space: nowrap;
            padding-right: 15px !important;

        }

        &>span{
            table-layout: fixed;
            width: 100%;
            word-break: normal;
            display: table;
            overflow-wrap: break-word;
        }
        
        span {
            white-space: pre-wrap;
            word-wrap: break-word;
        }
    }

    @media (max-width: $breakpoint-sm) {
        td {
            display: block;
            padding: 0 !important;

            &.ItemMetadata-key {
                margin-top: 15px;
            }
        }

        &:first-child td {
            margin-top: 0;
        }
    }
}

