@import "styles/_variables.scss";

.SkipToMainContent-skipNav {
    position: absolute;
    padding: 10px 20px;
    z-index: -1;
    background-color: #FFF;
    border: 1px solid #FFF;
    cursor: pointer;
    color: #0000EE;
    font-size: 16px;
    text-decoration: none;
    font-family: Helvetica,"Helvetica Neue",Arial,Verdana,sans-serif;
    font-style: normal;
}

.SkipToMainContent-skipNav:focus{
    z-index: 99;
}

@media print {
    .SkipToMainContent-skipNav {
        display: none;
    }
}
