.MobileModal-container {
    z-index: 100001;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #CCCCCC;

    padding: 10px;
    overflow-y: scroll;
}

.MobileModal-buttonContainer {
    padding-bottom: 5px;
}

.MobileModal-content {
    clear: right;
}
