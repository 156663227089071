.SearchTerms-container {
    composes: search-box from "styles/shared.css";
    background-color: #EFEFEF;
    border: 1px solid #EFEFEF;
    padding: 5px 10px;
}

.SearchTerms-searchTerms {
    font-weight: bold;
    color: #545454 !important;
    margin-top: auto;
    margin-bottom: 5px;
}
