.Footer-footerWrapper {
    flex: none;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
}

.Footer-footerContainer {
    padding: 6px;
    composes: box from "styles/shared.css";
}

@media print {
    .Footer-footerWrapper {
        display: none;
    }
}
