.Spinner-container {
    background: #efefef;
    padding: 10px;
    text-align: center;
}

.Spinner-spinner {
    color: black;
    font-size: 2.0rem;
    margin: 3px 5px;
    animation: rotateSpinner 1.2s infinite linear;
}

@keyframes rotateSpinner {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
}
