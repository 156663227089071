@import '../../styles/variables';

.SavedItemsCreateLinkModal-createLinkDialogFooter.Spinner-container {
    display: inline;
    background: transparent;
    position: relative;
    top: 6px;
}

.Spinner-spinner {
    font-size: 1.5rem;
}

.SavedItemsCreateLinkModal-createLinkModal {
    top: 160px;
}

.SavedItemsCreateLinkModal-createLinkDialogText {
    font-size: 1.1em;
    word-wrap: break-word;
}

.SavedItemsCreateLinkModal-createLinkDialogFooter {
    border-top: none;
    left: -2px;
    top: -7px;
    position: inherit;
    display: block;
    clear: right;
    margin-top: 2em;
    margin-bottom: -0.6em;
}

.SavedItemsCreateLinkModal-modalLinkButton {
    display: inline-flex;
    max-height: 31px; //keep same size as cancel button
    margin-left: 0.5rem !important; //important needed for moz
}

.SavedItemsCreateLinkModal-customSection {
    font-style: italic;
}

.SavedItemsCreateLinkModal-recaptcha {
    margin-top: 10px;
    float: right;
}

.SavedItemsCreateLinkModal-modalBackground {
    margin-top: 12px;
    background-color: #EEEEEE;

    input {
        margin: 0 10px;
        width: 96%; // Edge
        width: -moz-available; //Mozilla based browsers
        width: stretch; // WebKit based browsers -- updated version of fill-available
        border: 2px solid #999999;
    }
}

.SavedItemsCreateLinkModal-header {
    font-weight: bold;
    padding: 10px 10px 0 10px;
}

.SavedItemsCreateLinkModal-exampleContent {
    padding: 10px 10px 10px 10px;
}

.SavedItemsCreateLinkModal-createLinkHeader button{
    color: #767676;
    opacity: 1;
    &:hover {
        color: #767676;
        opacity: 1;
    }
}

@media screen and (max-width: 768px) {
    .SavedItemsCreateLinkModal-createLinkModal {
        top: 0;
    }
}

@media screen and (max-width: 812px) and (max-height: 414px){
    //Adjust for search header on horizontal mobile (including xl-sizes)
    .SavedItemsCreateLinkModal-createLinkModal {
        top: auto;
    }
}
