.ItemOptions-itemPrintOptions {
    display: flex;
    flex-direction: column;
}

.ItemOptions-itemOptions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .btn-toolbar .btn{
        padding: .25rem .5rem;
    }
}

.ItemOptions-itemShareRow {
    display: flex;
    justify-content: flex-end;
}

@media print {
    .ItemOptions-itemPrintOptions {
        display: none;
    }
}
