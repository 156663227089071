@import 'styles/_variables';

.ItemTextNav-itemTextNavHolder {
    float: right;
}

.ItemTextNav-itemTextNavHolderTop {
    position: fixed;
    top: 10px;
    transform: translateX(-95px);
    width: 100%;
}

.ItemTextNav-itemTextNavButtonPadding {
    margin-left: 5px;
    padding: 0.25rem 0.95rem !important;
}

@media (min-width: $breakpoint-sm) {
    .ItemTextNav-itemTextNavHolderTop {
        transform: translateX(-100%);
        margin-top: 155px;
        width: auto;
    }
}
