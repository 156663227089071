@import '../../styles/hacks';

.ImageViewer-container {
    display: block;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
}

.ImageViewer-buttonGroup{
    position: absolute;
    z-index: 1;
    button{
        background-color: transparent;
        border: 0;
        color: #ccc;
        font-size: 21px;
        padding: 5px 10px;
    }

    button:hover{
        color: #fff;
    }
}

:global {
    .fullpage {
        background: black;

        @include image-viewer-ie-ff();
    }
}
