.PDFViewer-pdfFrame {
    position: absolute;
    top: 38px;
    bottom: 10px;
    left: 10px;
    right: 10px;
}

.PDFViewer-pdfFrame iframe {
    width: 100%;
    height: 100%;
}
