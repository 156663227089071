.SearchFilter-mapBox {
    text-align: center;

    :global .panel {
        margin-bottom: 0;
        border-radius: 5px;
    }

    button {
        margin-top: .65em;
    }
}

.SearchFilter-collapsible {
    margin-bottom: 10px;
}
