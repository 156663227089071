.Footer-footerLanguageHolder {
    margin: auto;
    text-align: center;
    padding-bottom: 6px;
}

.Footer-footerLanguageHolder span {
    padding: 0 5px;
}

.Footer-footerLanguageHolder button {
    background: none !important;
    border: none;
    padding: 0 !important;
    cursor: pointer;
    color: #006FA7;
}
