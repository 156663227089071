@import '../../styles/variables';

.SavedItems-container {
    flex: 1 1 0;
    height: 100%;
    margin-bottom: 12px;
    padding-top: 20px;

    .SavedItems-displaySelectorAndResultsDesktopTop, .SavedItems-displaySelectorAndResultsDesktopBottom {
        display: inline;
        margin-left: 28%;
        :global {
            .ResultsDisplaySelector-displayOptionContainer {
                position: relative;
                left: 50%;
                margin-top: -48px;
                display: block;
            }
        }
    }

    .SavedItems-displaySelectorAndResultsMobileTop, .SavedItems-displaySelectorAndResultsMobileBottom {
        display: none;
    }

    .SavedItems-buttonGroup {
        display: inline;
    }

    .SavedItems-savedItems {
        float: right;
        margin-right: 50px;
        margin-top: -7px;
    }

    .SavedItems-disabled {
        color: #CCC;
    }

    .SavedItems-alert {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }

    .SavedItems-spinner {
        margin: 3px 5px;
        height: 14px;
        width: 12px;
        animation: rotateSpinner 1.2s infinite linear;
        float: right;
    }

    @keyframes rotateSpinner {
        0%    { transform: rotate(0deg); }
        100%  { transform: rotate(360deg); }
    }

    .SavedItems-pageTitle {
        top: 16px;
        font-size: 1.125rem;
    }

    .SavedItems-faTitle {
        padding: .5em .5em 0 30px;
    }

    .SavedItems-divider {
        margin-left: 30px;
        margin-right: 30px;
    }

    .SavedItems-noSavedItems {
        padding-top: 5px;
        margin-left: 30px;
        font-size: .875rem;
    }

    .SavedItems-sessionWarning {
        margin-left: 30px;
        margin-right: 2em;
        display: block;
        font-size: .875rem;
    }

    .SavedItems-buttonContainer {

        margin-top: 10px;

        .SavedItems-selectionInput {
            margin-left: 45px;
            padding-right: 20px;
            display: inline-block;

            label {
                font-size: .875rem;
            }
        }

        .SavedItems-selectionInputLabel {
            padding-top: 5px;
        }

        input {
            position: absolute;
            top: 0em;
        }

        button {
            margin: 5px 0 10px 7px;
            height: 33px;

            .SavedItems-trash {
                padding-right: 0.5rem;
                position: relative;
                top: -1px;
            }
        }
    }

    .SavedItems-url {
        text-decoration: underline !important;
    }

    .SavedItems-notification {
        margin: 15px 0 15px 30px;
    }
}
.SavedItems-desktopIcon {
    display: inline;
}
.SavedItems-mobileIcon {
    display: none;
}

@media screen and (max-width: $breakpoint-md) and (min-width: $breakpoint-sm + 1) {

    .SavedItems-container {
        .SavedItems-displaySelectorAndResultsDesktopTop, .SavedItems-displaySelectorAndResultsDesktopBottom {
            display: inline !important;
            margin-left: 12% !important;

            :global {
                .ResultsDisplaySelector-displayOptionContainer {
                    position: relative;
                    left: 60%;
                    margin-top: -48px;
                    display: block;
                }
            }
        }
    }
}

// Mobile View (Phone, Tablet)
@media screen and (max-width: $breakpoint-sm)  {

    .SavedItems-container {

        .SavedItems-removeItemsModal {
            top: auto;
        }

        .SavedItems-noSavedItems {
            padding-top: 5px;
            margin-left: 1rem;
            font-size: .875rem;
        }

        .SavedItems-faTitle {
            padding: .5em .5em 0 15px;
        }

        .SavedItems-divider {
            margin-left: 15px;
            margin-right: 15px;
        }

        .SavedItems-alert {
            margin-left: 15px !important;
            margin-right: 15px !important;
        }

        .SavedItems-sessionWarning {
            margin-left: 15px;
            margin-right: 15px;
            display: block;
            font-size: .875rem;
        }

        .SavedItems-buttonContainer {

            .SavedItems-selectionInput {
                margin-left: 30px;
                padding-top: 10px;
            }

            input {
                position: absolute;
                top: 0.67em;
            }

            .SavedItems-buttonText {
                display: none;
            }

            .SavedItems-buttonGroup {

                float: right;
                margin-right: 35px;
                margin-top: 6px;

                button {
                    width: 35px;
                    height: 35px;
                    display: inline-flex;
                    justify-content: center;

                    .SavedItems-trash {
                        padding-right: 0;
                        top: -2px; //this icon has whitespace at the top, making it look off-center
                    }
                }
            }

            // Yep, don't render the view selector on the bottom in mobile view
            .SavedItems-displaySelectorAndResultsDesktopTop,
            .SavedItems-displaySelectorAndResultsDesktopBottom,
            .SavedItems-displaySelectorAndResultsMobileBottom {
                display: none;
            }

            .SavedItems-displaySelectorAndResultsMobileTop {
                display: block;
                margin-left: 30px;
            }

            .SavedItems-savedItems {
                display:inline;
                float: right;
                margin-right: 34px;
                margin-top: -6px;
            }
        }
    }
    .SavedItems-desktopIcon {
        display: none;
    }
    .SavedItems-mobileIcon {
        display: inline;
    }
}
