.About-maincontainer {
  composes: box from "styles/shared.css";
  margin-bottom: 10px;
  flex-grow: 1;
}

.About-maincontentAbout {
  composes: contentContainer from "styles/shared.css";
}

.About-maincontentAboutTitle {
}

.About-maincontentAboutTitle h1 {
}

.About-mainAboutCopy {
}

.shared-box {
  border-radius: 5px;
  background-color: #fff;
}

.shared-contentContainer {
  width: 80%;
  margin: 10px auto;
}
