.PreviewBanner-previewBarContainer {
    background-color: #ACE8E6;
    padding: 20px;
    overflow: auto;
    width: 100%;
}

.PreviewBanner-previewBarContainer button {
    border: 1px solid rgb(187,187,187);
    background-color: rgb(239, 239, 239);
    color: rgb(67,67,67);
}

.PreviewBanner-previewBarContainer button:hover {
    background-color: rgb(67,67,67);
    color:rgb(255,255,255);
    border-color: rgb(67,67,67);
}
