.FooterNavigationLinks-footerLinksHolder {
    margin: auto;
    text-align: center;
    padding:0 0 6px 0;
    max-width: 100%;
}

.FooterNavigationLinks-footerLinksHolder {
    li {
        white-space: initial;
        word-break: break-all;
        word-wrap: break-word;
        padding: 0 5px;
        display: inline-block;
        /* You can also add some margins here to make it look prettier */
        zoom:1;
        *display:inline;
        /* this fix is needed for IE7- */
    }

    a {
        font-weight: bold;
    }

    a:hover {
        text-decoration: underline;
    }

}

.FooterNavigationLinks-footerExternalLinkIcon {
    margin-left: 3px;
}

.FooterNavigationLinks-hideText {
    display:inline-block;
    text-indent: 100%;
    width:0;
    height: 0;
    white-space: nowrap;
    overflow: hidden;
}
