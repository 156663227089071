.ItemPreview-container {
    display: flex;

    & > button{
        width:30px;
        font-size: 2em;
        padding: 0;
        border: none;
        border-radius: 0;

        .text {
            display: none;
        }
    }

    .preview {
        min-height: 65px;
        flex:1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.preview {
    min-height: 65px;
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
}
