.FetchingNotification-spinner {
  margin: 3px 5px;
  height: 14px;
  width: 12px;
  animation: rotateSpinner 1.2s infinite linear;
  float: right;
}

@keyframes rotateSpinner {
  0%    { transform: rotate(0deg); }
  100%  { transform: rotate(360deg); }
}
