.ContentHeader-maincontainer {
    composes: box from "styles/shared.css";
    padding: 10px;
    margin-bottom: 10px;
}

.ContentHeader-mainImageHolder {
    text-align: center;
}

.ContentHeader-mainImageHolder img{
    max-width: 100%;
    height:auto;
}

.ContentHeader-mainCopyHolder {
    padding-top: 10px;
    width: 80%;
    text-align:left;
    margin: 0 auto 0;
}

.shared-box {
    border-radius: 5px;
    background-color: #fff;
}

.ContentHeader-position {
    position: relative;
}
