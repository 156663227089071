@import "styles/_variables.scss";

.Paginator-paginationwrapper{
    background-color:#CCCCCC;
    margin: 0;
    clear: both;
    height: 49px;

    &>div{
        display: flex;
        justify-content: center;
    }
}

.Paginator-flexGrow {
    flex: 1;
}

.Paginator-pagination {
    margin: 0;
    margin-bottom: 10px;

    li {
        border-radius: 0;

        a {
            color: #545454;
        }

        a:hover {
            text-decoration: underline;
            border:solid 1px transparent;
        }
    }

    :global {
        .active {
            a, a:hover, a:active {
                border:solid 1px transparent;
                text-decoration: none;
            }
        }
    }
}

.Paginator-paginationInfo{
    margin-left: 10px;
    padding: 10px 0;

    h3 {
        margin: 0;
    }
}

.Paginator-paginationPageHolder{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.paginationPageNoInfo{
    background-color:#CCCCCC;
    padding-top: 10px;
    width: 100%;
}

@media (max-width: $breakpoint-md) {
    .Paginator-paginationInfo{
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .Paginator-paginationwrapper{
        height: 100%;
    }

    .Paginator-paginationPageHolder{
        text-align: center;
        flex-direction: column;

        .Paginator-pagination {
            margin-top: 10px;
            margin-bottom: 15px;
        }
    }

    .Paginator-paginationwrapper>div{
        flex-direction: column;
    }
    .Paginator-flexGrow{
        display: flex;
        justify-content: center;
    }
}
