.SaveRemoveAllItemsButton-buttonContainer {
    background-color: inherit;
    display: flex;
    float: right;
    position: relative;
    margin: -45px 1px 0 -1px;
}

.SaveRemoveAllItemsButton-favoriteButton {
    z-index: 1;
    background-repeat: no-repeat;
    border: none;
    overflow: hidden;
    outline: none;
    color: inherit !important;
    background-color: #efefef;
}

.SaveRemoveAllItemsButton-favoriteButton:active, .SaveRemoveAllItemsButton-favoriteButton:focus {
    box-shadow: none;
}

.SaveRemoveAllItemsButton-itemFavorite {
    margin-top: 6px;
}

.SaveRemoveAllItemsButton-saveIcon, .SaveRemoveAllItemsButton-savedIcon {
    padding-right: 5px;
}

.SaveRemoveAllItemsButton-buttonSpan {
    z-index: 1
}

.SaveRemoveAllItemsButton-modalText {
    font-size: 1.1em;
}

.SaveRemoveAllItemsButton-modalFooter {
    border-top: none;
}

.SaveRemoveAllItemsButton-maxItemsModalHeader button{
    color: #767676;
    opacity: 1;
    &:hover {
        color: #767676;
        opacity: 1;
    }
}

.SaveRemoveAllItemsButton-favoritesModal {
    top: 160px;
}

@media screen and (max-width: 1200px) {
    .SaveRemoveAllItemsButton-buttonContainer {
        display: inherit;
        background-color: inherit;
        margin-bottom: 20px;
        margin-top: -8px;
        float: none;
        position: inherit;
        height: 20px;
    }

    .SaveRemoveAllItemsButton-favoriteButton {
        float: right;
        position: relative !important;
        margin-bottom: -8px !important;
    }
}

@media screen and (max-width: 812px) and (max-height: 414px){
    //Adjust for search header on horizontal mobile (including xl-sizes)
    .SaveRemoveAllItemsButton-favoritesModal {
        top: auto;
    }
    .SaveRemoveAllItemsButton-buttonContainer {
        display: grid;
    }
}
@media screen and (max-width: 768px) {
    .SaveRemoveAllItemsButton-itemFavorite {
        padding-left: 15px !important;
    }

    .SaveRemoveAllItemsButton-favoritesModal {
        top: 0;
    }

    .SaveRemoveAllItemsButton-buttonContainer {
        margin-top: -2px;
    }
}
