@import '../../styles/variables';

.SelectableItemGridCard-container {
    display: inline-flex;
    width: 180px;
    margin: 3px 35px 17px 20px;
    padding: 10px;
    border-radius: 5%;
    background-color: white;
    text-align: center;
    vertical-align: top;
    height: 200px;
    flex-direction: column;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

// Webkit browsers (chrome, safari, etc)
@supports (-webkit-appearance:none) {
    .SelectableItemGridCard-container {
        display: inline-grid;
    }
}

// Firefox
@supports (-moz-appearance:none) {
    .SelectableItemGridCard-container {
        display: inline-flex;
    }
}

.SelectableItemGridCard-item {
    border: 1px solid #CCC;
}

.SelectableItemGridCard-selectItem {
    margin: 4px 0 0 0 !important;
    position: absolute;
}

.SelectableItemGridCard-thumbnail {
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: 160px;
    max-height: 120px;
}

.SelectableItemGridCard-cardTitle {
    display: block;
    height: 60px;
    text-overflow: ellipsis;
    overflow: hidden;
    width:160px;
}

.SelectableItemGridCard-thumbnailHolder {
    display: block;
    width: 160px;
    height: 120px;
}

// https://jira.oclc.org/browse/CDMRUI-3400
@media screen and (max-width: $breakpoint-sm)  {

    // Webkit browsers (chrome, safari, etc)
    @supports (-webkit-appearance:none) {
        .SelectableItemGridCard-container {
            margin: 8px 12px 50px 2px;
        }

        .SelectableItemGridCard-selectItem {
            margin: -10px 0 8px 3px !important;
        }
    }

    // Firefox
    @supports (-moz-appearance:none) {
        .SelectableItemGridCard-container {
            margin: -3px 12px 50px 2px;
        }

        .SelectableItemGridCard-selectItem {
            margin: 10px 0 8px -120px !important;
            position: relative;
            padding-bottom: 5px !important;
        }
    }

    .SelectableItemGridCard-thumbnailHolder {
        width: 140px;
    }

    .SelectableItemGridCard-thumbnail {
        max-width: 140px;
    }

    .SelectableItemGridCard-container {
        width: 160px;
        padding-top: 19px;
    }

    .SelectableItemGridCard-cardTitle {
        width:140px;
    }
}
