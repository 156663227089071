// override core.scss
.ItemDownloadImage-itemDownloadDropdown{
    float: left!important;
    padding-right: 0!important;

    button {
        .caret {
            margin-top: -10px;
            border-width: 7px;
        }
    }
}


