@import 'styles/_variables';
@value text-color from 'styles/colors.css';

.ItemSearch-itemSearchButton a {
    color: #ffffff;
}

.ItemSearch-itemHeaderButtonPadding, .ItemSearch-itemStackedHeaderButtonPadding {
    margin-right: 5px;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem !important;
}

.ItemSearch-itemSearchInput {
    flex: 2 0 0px;
    margin: 5px 10px 0 5px;
}

.ItemSearch-itemSearchLabel {
    width: 100%;
    text-align: left;
    padding-left: 5px;
    margin-bottom: 0;
    color: #434343;
    font-size: 0.875rem;
    font-weight: normal;
}

.ItemSearch-itemSearchInput > div {
    font-size: .875rem;
    font-weight: bold;
}

.ItemSearch-itemSearchInputControl {
    font-size: 1rem;
    color: text-color;
    width: 100%;
}

.ItemSearch-itemSearchResults {
    font-size: .875rem;
    font-weight: bold;
    display: block;
    padding-left: 5px;
    color: #434343;
}

.ItemView-itemSearchContainer {
    background-color: #EFEFEF;
    margin-top: 10px;
    padding: 5px;
    border-top: 5px solid #aaa;
}

.ItemSearch-itemSearchControl {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
}

@media screen and (min-width: $breakpoint-sm)  {
    .ItemSearch-itemSearchInput {
        top: 60px;
    }

    .ItemSearch-itemSearchInputControl {
        font-size: 17px !important;
        top: 10px;
    }
}

@media print {
    .ItemSearch-itemSearchPrint {
        display: none;
    }
}

