@import 'styles/_variables';

button.ItemViewPager-angle {
    padding: 0.1rem .5rem;
}

button.ItemViewPager-angle span {
    margin: -4px 0.25rem -5px .15em;
    padding: 3px 0;
}

.ItemViewPager-result {
    padding: 0 5px;
}

.ItemViewPager-result::first-letter {
    text-transform: capitalize;
}

.ItemViewPager-pager {
    text-align: right;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

@media (min-width: $breakpoint-sm) {
    .ItemViewPager-pager {
        text-align: right;
        border: none;
    }
}

@media print {
    .ItemViewPager-pager {
        display: none;
    }
}
