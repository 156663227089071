@import 'styles/_variables';

.BackToResults-arrowPadding {
    padding-right: 5px;
}

.BackToResults-backLink {
    text-align: left;
    float: left;
    padding-top: 5px;
}

@media (min-width: $breakpoint-sm) {
    .BackToResults-backLink {
        text-align: right;
        float: none;
        padding-top: 3px;
    }
}
