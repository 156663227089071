@value modal-background-color from 'styles/colors.css';
@value dividing-line-color from 'styles/colors.css';

.MobileSortModal-container {
    clear: right;
    padding-top: 5px;

    .radio {
        font-size: 1.2em;

        label {
            margin-bottom: 10px;
        }
    }
}
