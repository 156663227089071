.IEPage-container {
    display: block;
    width: 100%;
    height: 100%;
}

.IEPage-modalHeader {
    color: red;
    text-align: center;
    border-bottom: none;
}

.IEPage-modalBody {
    color: black;
    text-align: center;
}

.IEPage-modalFooter {
    background-color: #ACE8E640;
    border-top: none;
    text-align: center;
}

@media (min-width: 768px) {
    .modal-dialog {
        width: 450px;
    }
}


