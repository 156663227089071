@import '../../styles/variables';

.AdvancedSearch-container {
    composes: box from "styles/shared.css";
    margin-bottom: 10px;
    flex-grow: 1;
}

.AdvancedSearch-sectionWrapper {
    padding-bottom: 10px;
    border-bottom: 2px solid #CCCCCC;

    &:last-child {
        border: 0;
    }

    .well {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .alert {
        margin: 10px 10px 0;
    }
}

.AdvancedSearch-content {
    float: none;
    margin: 0 auto;

    h1 {
        margin: 0;

        &:first-child {
            margin-top: 10px;
        }
    }

    hr {
        margin: 5px 0;
    }

    @media (min-width: $breakpoint-sm){
        .col-xs-6 {
            width: percentage(2/12);
        }

        .btn-see-more-less{
            width: percentage(4/12);
        }
    }
}

.AdvancedSearch-addMoreButtonContainer {
    @media (min-width: $breakpoint-sm) {
        .col-sm-2 {
            padding-left: 5px;
        }
    }
}

.AdvancedSearch-buttonsBox{
    margin-top: 10px;

    .col-xs-6:first-child{
        padding-right: 5px;
    }
    .col-xs-6:last-child{
        padding-left: 5px;
    }
}
