.SearchHeader-container {
    composes: search-box from "styles/shared.css";
    background-color: #EFEFEF;
    border: 1px solid #EFEFEF;
    padding: 5px 10px 5px 10px;
}

.shared-search-box {
    margin-bottom: 10px;
    border: 1px solid #fff;
}
