@import "styles/_variables.scss";

@media (min-width: $breakpoint-sm) {
    .col-sm-8 .results {
        display: none;
    }
    .results {
        font-size: .875rem;
        font-weight: bold;
        display: block;
        padding: 0 5px 5px;
    }

}

@media (max-width: $breakpoint-sm) {
    .results {
        font-size: .875rem;
        font-weight: bold;
        display: block;
        padding: 0 10px 5px;
    }
}

.TreeView-root {
    list-style: none;
    padding: 0;
    position: relative;
    margin: 0 0 -1px 0;
    overflow-y: auto;
    border-top: 3px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    -webkit-overflow-scrolling: touch;

    li[role="treeitem"]:focus {
        outline: 0;

        & > h2{
            outline: thin dotted;
            outline: 5px auto -webkit-focus-ring-color;
            outline-offset: -2px;
        }
    }

    button[role="treeitem"]:focus {
        outline: thin dotted;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }

    button {
        display: block;
        text-decoration: none;
        width: 100%;
        &:hover {
            text-decoration: none;
        }
    }

    /**
     *  WebKit gets its native focus styles.
     */
    @media (-webkit-min-device-pixel-ratio:0) {
        li[role="treeitem"]:focus {
            outline: 0;

            & > h2{
                outline: 5px auto -webkit-focus-ring-color;
            }
        }
        button[role="treeitem"]:focus {
            outline: 5px auto -webkit-focus-ring-color;
        }
    }

    ul {
        list-style: none;
        padding: 0;
    }

    .node {
        padding-top: 1px;
        background-color: #fff;

        &.leaf {
            background-color: #efefef;

            .header.matched {
                border-left-color: #CD0A0A;
            }

            &.selected {
                background: #FFF08C;
            }
        }

        &.folder {
            &>.header.matched {
                border-left-color: #CD0A0A;
                border-left-style: double;
            }
            &>.header>.title {
                color: #545454;
            }
        }

        .header {
            border-left: 5px solid transparent;
            margin:0;
            cursor: pointer;
            text-align: left;

            .title {
                border-bottom: 1px solid #ccc;
                position: relative;
                padding: 10px 0 10px 0;
                // inherit from parent, which comes from WCT settings
                font-size: inherit;
                font-weight: inherit;
                font-style: inherit;

                span.fa {
                    position: absolute;
                    left: -1.1em;
                }
            }
        }

        @for $i from 1 through 10{
            .level-#{$i}{
                margin-left: 10px + 10px * $i;
            }
        }
    }
}
