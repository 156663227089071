.SearchInfo-infoBox {
    .SearchInfo-nonWctHeader {
        color: #545454;
    }

    h1 {
        font-weight: normal;
        font-size: 0.875rem;
    }

    h1.hidden-xs {
        margin: 0;
    }

    h1.visible-xs{
        margin: 0 0 10px 0;
    }
}
