.RemoveSavedItemsModal-removeItemsModal {
    top: 160px;
}

.RemoveSavedItemsModal-removeDialogText {
    font-size: 1.1em;
}

.RemoveSavedItemsModal-removeDialogFooter {
    border-top: none;
}

.RemoveSavedItemsModal-removeHeader button{
    color: #767676;
    opacity: 1;
    &:hover {
        color: #767676;
        opacity: 1;
    }
}

.RemoveSavedItemsModal-modalRemoveButton {
    max-height: 31px;
    display: inline-flex;
    margin-left: 0.5rem !important; //important needed for moz
}

@media screen and (max-width: 812px) and (max-height: 414px){
    //Adjust for search header on horizontal mobile (including xl-sizes)
    .RemoveSavedItemsModal-removeItemsModal {
        top: auto;
    }
}

@media screen and (max-width: 768px) {
    .RemoveSavedItemsModal-removeItemsModal {
        top: 0;
    }
}
