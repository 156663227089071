.ResultsPerPageSelector-resultsPerPageSelectorFlexContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.ResultsPerPageSelector-resultsPerPageSelectorLabel {
    margin-top: 0;
    margin-bottom: 0;
}

.ResultsPerPageSelector-resultsPerPageSelector {
    margin-left: 5px;
    min-width: 50%;
}
