.ItemUrl-itemUrl {
    display: flex;
}

.ItemUrl-itemUrlLink {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 5px 0 0 10px;
    max-width: 50vw;
}

.ItemUrl-itemImageThumbnail {
    max-width: 100%;
    flex: 0 0 auto;
    align-self: flex-start;
}

.ItemUrl-thumbnailFlex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
