.CompoundItemPagination-container {
    padding: 10px;
    text-align: center;
    width: 100%;
}

.CompoundItemPagination-result {
    padding: 0 5px;
}

.CompoundItemPagination-pageInput {
    width: 55px;
    margin-right: 5px;
}

@media print {
    .CompoundItemPagination-container {
        display: none;
    }
}
