@import "../../styles/_variables";

.LoginView-maincontainer {
    composes: box from "../../styles/shared.css";
    margin-bottom: 10px;
    flex-grow: 1;
}
.LoginView-forgotPassword {
    margin: 2em 0 2em 0;
    text-decoration: underline;
    text-align: center;
    margin-right: 15px;
}

.LoginView-createAccount {
    margin: 2em 0 2em 0;
    text-decoration: underline;
    text-align: center;
    a {
        font-weight: bold;
    }
    margin-right: 15px;
}

.LoginView-registration {
    color: #545454;
    padding-bottom: 1em;
    padding-left: 1.2em;
    width: 90%;
    margin-left: 0px;
}

.LoginView-maincontentLogin {
    composes: contentContainer from "../../styles/shared.css";

    h1 {
        padding-bottom: 10px;
    }

    alert {
        float: right;
    }

    .form-horizontal {
        max-width: 350px;
    }

    .control-label {
        text-align: left;
    }

    .form-group {
        display: flex;
    }

    .form-control {
        height: 36px;
    }

    button {
        width: 335px;
        max-width: 100%;
    }

    input {
        max-width: 240px;
    }

    label {
        display: inline-block;
        align-self: center;
        font-weight: bold;
        margin: -5px 0 0 0;
    }

    form {
        max-width: 350px;
        margin: 0 auto;
        padding-top: 1em;

        .LoginView-loginFormControlLabel {
            font-weight: bold;
        }
    }

    // solution from http://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone#answer-16255670
    @media screen and (-webkit-min-device-pixel-ratio:0) and (max-device-width:1024px){
        input {
            font-size: 1rem;
        }
    }
}

@media only screen and (max-width: $breakpoint-sm) {

    form {
        max-width: 350px;
        margin: 0 auto;
        padding-top: 1em;

        .LoginView-loginFormControlLabel {
            text-align: left;
        }
    }
    // solution from http://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone#answer-16255670
    @media screen and (-webkit-min-device-pixel-ratio:0) and (max-device-width:1024px){
        input {
            font-size: 1rem;
        }
    }
}
