.CollectionLayout-container {
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -ms-flex-direction: column;
    flex: 1 0 auto;
    -webkit-flex: 1 0 auto;
}

.CollectionLanding-aboutCollection {
    margin: 10px 0 10px 0;
}

.CollectionLanding-landingLinkContainer {
}

.CollectionLanding-mainLandingImage {
    text-align: center;

    img {
        max-width: 100%;
        height: auto;
    }
}

.CollectionLanding-landingLinks {
    margin: auto;
    list-style: none;
    padding: 0px;
    text-align: center;
}

.CollectionLanding-landingBrowseButton {
    margin-bottom: 10px !important;
    min-width: 15em;
}

.CollectionLanding-landingLinks li {
    margin-top: 2px;
    margin-bottom: 3px;
}

.CollectionLanding-pageTitle {
    overflow: hidden;
    text-overflow: ellipsis;
}

.CollectionLanding-maincontainer {
    composes: box from "styles/shared.css";
    padding: 10px 0;
    margin-bottom: 10px;
    flex-grow: 1;
}

.CollectionLanding-maincontentLanding  {
    composes: contentContainer from "styles/shared.css";
}

@media not all and (min-resolution:.001dpcm){
    @supports (-webkit-appearance:none) {
        .CollectionLayout-container {
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
        }
    }
}
