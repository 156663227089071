.ItemImage-itemImage {
    display: table-cell;
    max-width: 730px;

    > div {
        display: table-cell;
        position: relative;
    }

    img {
        max-width: 730px;
        width: 100%;
    }
}

.ItemImage-expandButton {
    position: absolute;
    top: 1em;
    right: 1em;

    .fa{
        font-size: 1.5em;
    }
}

@media print {

    .ItemImage-expandButton {
        display: none;
    }
    .ItemImage-itemImage {
        page-break-before: avoid;
        break-before: avoid;
        page-break-inside:avoid;
        break-inside: avoid;
        background-color: white;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 50px;
        left: 0;
        margin: 0;
        padding: 15px;
        font-size: 14px;
        line-height: 18px;
    }
}
