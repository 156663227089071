.MobileSearchHeader-generateMapContainer{
    :global{
        .btn{
            margin-top: 20px;
        }
    }
}

.MobileSearchHeader-buttonGroup {
    display: flex;
    flex-flow: row wrap;

    button {
        flex: 1;
    }

    button+button {
        margin-left: 10px;
    }
}
