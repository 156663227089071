.ItemPDF-expandButton {
    position: absolute;
    top: 1em;
    right: 1em;

    .fa{
        font-size: 1.5em;
    }
}

.ItemPDF-itemImage {
    display: table-cell;
    width: 730px;

    > div {
        display: table-cell;
        position: relative;
    }

    img {
        max-width: 730px;
        width: 100%;
        box-shadow: 0 6px 12px rgba(0,0,0,0.2), 0 0 3px rgba(0,0,0,0.1);
    }
}
