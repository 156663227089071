.Search-container {
    margin-left: 10px;
    margin-right: 10px;
}

.Search-alertArea {
    padding-left: 5px;
    padding-right: 5px;
}

.Search-filterContent {
    padding-left: 5px;
    padding-right: 5px;
}

.Search-mainContent {
    padding-left: 5px;
    padding-right: 5px;
}

:global(.noscroll){
    :local(.mainContent){
        position: static;
    }
}
