@mixin button-focus() {
    outline: 1px dashed #81BCF9;
}

@mixin image-viewer-ie-ff(){

    // only for IE
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        button:focus {
            @include button-focus();
        }
    }
    // only for FF
    @media all and (-moz-images-in-menus:0) {
        @media (min-width: 0px) {
            button:focus {
                @include button-focus();
            }
        }
    }
}
